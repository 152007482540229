.ta-confirm-mrspex__container .ta-dotted-title.ta-popup__small_title {
  color: #202020;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 0;
  margin-bottom: 0;
  margin-top: 12px;
}

.ta-confirm-mrspex__container .ta-confirm-mrspex__row {
  color: #1f1f1f;
  font-size: 12px;
  border-top: 1px solid #d4d6d7;
  padding: 10px 0 10px 70px;
  position: relative;
}

.ta-confirm-mrspex__container .ta-confirm__row {
  display: flex;
  color: #1f1f1f;
  font-size: 12px;
  border-top: 1px solid #d4d6d7;
  position: relative;
}

.ta-confirm-mrspex__container .ta-confirm__row .ta-confirm__label {
  width: unset;
  opacity: 0.54;
  color: #1f1f1f;
  font-weight: 500;
  z-index: 2;
  margin-right: 10px;
}

.ta-confirm-mrspex__container .ta-confirm-mrspex__title {
  opacity: 0.54;
  color: #1f1f1f;
  position: absolute;
  top: 10px; 
  left: 0;
  z-index: 2;
}

.ta-confirm-mrspex__container .ta-confirm-mrspex__note {
  opacity: 0.54;
  color: #1f1f1f;
  margin-left: 4px;
}

.ta-confirm-mrspex__container .ta-disclaimer {
  font-size: 12px;
  color: #505a5e;
  padding: 16px 0;
  border-top: 1px solid #d4d6d7;
  border-bottom: 1px solid #d4d6d7;
  word-break: break-word;
}

.ta-confirm-mrspex__container .ta-disclaimer a {
  color: #505a5e;
  font-weight: 500;
  text-decoration: underline;
}