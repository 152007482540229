.ta-customer-fields__container {
  padding: 86px 8px 64px 8px;
}

.ta-customer-fields__container .ta-popup__main_title {
  margin-bottom: 24px;
}

.ta-customer-fields__field {
  margin-bottom: 8px;
}

.ta-customer-fields__hidden {
  display: none;
}

.ta-customer-fields .ta-auth-register__checkbox .ta-form-group {
  padding-top: 0;
}

.ta-customer-fields .ta-auth-register__checkbox .ta-checkbox-wrapper {
  width: 100%;
}

.ta-checkbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-checkbox-container .ta-hover-popup__content {
  width: max-content;
  max-width: 256px;
  white-space: pre-line;
  text-align: left;
}

.ta-checkbox-container .ta-hover-popup {
  display: table;
}

.ta-checkbox-container .ta-hover-popup__trigger i {
  vertical-align: middle;
}

.ta-customer-fields .ta-checkbox-container span a {
  text-decoration: underline;
  color: #505a5e;
}

.ta-customer-fields .ta-checkbox-container .ta-form-group {
  padding-top: 0;
}

.ta-customer-fields .ta-checkbox-container .ta-checkbox-wrapper {
  width: 100%;
}

.ta-customer-fields .ta-checkbox-container .ta-form-error.active {
  margin-top: 5px;
}

.ta-customer-fields .ta-checkbox-container .ta-form-error.active span {
  color: #cb3b43;
}

.ta-customer-fields .ta-disclaimer {
  font-size: 12px;
  color: #505a5e;
  padding: 16px 0;
  border-top: 1px solid #d4d6d7;
  border-bottom: 1px solid #d4d6d7;
  word-break: break-word;
}

.ta-customer-fields .ta-disclaimer a {
  color: #505a5e;
}

@media only screen and (max-width: 767px) {
  .ta-customer-fields__container {
    padding-top: 102px;
  }
}
