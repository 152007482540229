.ta-confirm__container {
  padding-top: 100px;
  padding-bottom: 60px;
}

.ta-confirm__container .ta-popup__main_title {
  margin-bottom: 16px;
}

.ta-confirm__row {
  line-height: 14px;
  font-size: 12px;
  border-top: 1px solid #d4d6d7;
  padding: 10px 10px 10px 0;
  position: relative;
}

.ta-confirm__row.price {
  padding: 8px;
  border-top: 0;
  border-radius: 3px;
  background-color: #f4f3f3;
  position: relative;
  margin-top: 10px;
}

.ta-confirm__row.price::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  top: -10px;
  left: 0;
  background-color: #d4d6d7;
}

.ta-confirm__row.service {
  padding: 5px 0;
}

.ta-confirm__row__service-data,
.ta-confirm__row__price-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-confirm__row__price-data__no-flex {
  display: block;
}

.ta-confirm__row__price-data:not(:first-of-type) {
  border-top: 1px solid #d4d6d7;
}

.ta-confirm__row.price .ta-confirm__row__price-data .ta-confirm__label,
.ta-confirm__row.price .ta-confirm__row__price-data span:not(.price) {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ta-confirm__row__price-data i {
  padding-right: 4px;
}

.ta-confirm__row.price > :first-child .ta-confirm__label,
.ta-confirm__row.price > :first-child span {
  margin-top: 0;
}

.ta-confirm__row.price > :last-child .ta-confirm__label,
.ta-confirm__row.price > :last-child span {
  margin-bottom: 0;
}

.ta-confirm__row__service-data__name {
  display: flex;
  align-items: center;
}

.ta-confirm__row .ta-confirm__icon {
  width: 16px;
  font-size: 12px;
  text-align: center;
  margin-right: 4px;
}

.ta-confirm__icon__price-calculated {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.26);
}

.ta-confirm__rows .ta-confirm__icon__price-calculated.flex {
  padding-left: 4px;
  display: flex;
}

.ta-confirm__row .ta-confirm__value {
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #b6babd;
  padding: 4px 8px;
  border-radius: 12px;
  text-align: center;
  white-space: nowrap;
  z-index: 2;
}

.ta-confirm__row .ta-confirm__value span {
  display: inline;
}

.ta-confirm__row .ta-confirm__label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-confirm__row .ta-confirm__file-row {
  height: 32px;
  padding: 0 8px;
  border-radius: 3px;
  border: solid 1px #cbced0;
  background-color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: #505a5e;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ta-confirm__row .ta-confirm__file-row:not(.last-child) {
  margin-bottom: 4px;
}

.ta-confirm__container .ta-lined-title {
  margin-top: 16px;
}

.ta-confirm__rows_container .ta-confirm__row:first-child {
  border-top: 0;
}

.ta-message.type-warning.ta-confirm__notification {
  background: #fff;
  margin: -16px -16px 16px -16px;
  border-radius: 0;
}

.ta-message.type-warning.ta-confirm__notification.active {
  margin-bottom: 24px;
  box-shadow: rgba(0, 0, 0, 0.26) 0 0 6px 0;
}

.ta-confirm__container .ta-confirm__timezone {
  color: rgba(80, 9,0 94, 0.7);
  font-size: 11px;
  font-weight: 300;
  font-style: italic;
  padding: 0;
  margin: 0 0 0 4px;
}

.ta-confirm__extra-persons-button {
  text-decoration: underline;
  cursor: pointer;
}

.ta-confirm .ta-disclaimer {
  font-size: 12px;
  color: #505a5e;
  padding: 16px 0;
  border-top: 1px solid #d4d6d7;
  border-bottom: 1px solid #d4d6d7;
  word-break: break-word;
}

.ta-confirm .ta-disclaimer a {
  color: #505a5e;
  font-weight: 500;
  text-decoration: underline;
}

.ta-confirm .ta-confirm__row__transaction-fee__label {
  font-style: italic;
  font-size: 12px;
  color: #505a5e;
  font-weight: 300;
  line-height: 1.17;
  margin: 8px 0;
}

.ta-confirm__row__transaction-fee__gateway-list {
  list-style: disc;
  list-style-position: inside;
  font-style: italic;
  font-size: 12px;
  line-height: 1.17;
}
